.header {
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--header-z-index);

  transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1);

  &--detached {
    position: fixed;
    transform: translateY(-100%);
  }

  &::after {
    content: "";
    display: block;

    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    pointer-events: none;

    transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: var(--v-spacer-s) 0;
    color: var(--white);
    margin: 0 auto;

    position: relative;
    z-index: 2;

    transition: background-color 0.6s cubic-bezier(0.23, 1, 0.32, 1);

    background-color: rgba(255, 255, 255, 0);
    box-shadow: 0 4px 16px -8px rgba(0, 0, 0, 0);

    @media (--laptop) {
      align-items: flex-start;
    }

    .header--detached &,
    .header--slided-in & {
      color: var(--gray-400);
      padding: 0;
      transition: padding 0.6s cubic-bezier(0.23, 1, 0.32, 1),
        background-color 0.6s cubic-bezier(0.23, 1, 0.32, 1),
        box-shadow 0.6s cubic-bezier(0.23, 1, 0.32, 1),
        transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    .header--detached & {
      background-color: var(--white);
    }
    .header--slided-in & {
      /* transform: translateY(100%); */
      box-shadow: var(--box-shadow);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    border-bottom: 1px solid;
    border-color: var(--black-alpha);
    transition: border-color 0.4s cubic-bezier(0.23, 1, 0.32, 1);

    .header--detached &,
    .header--slided-in & {
      border-color: transparent;
    }

    @media (--laptop) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__nav {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--primary {
      margin-bottom: var(--v-spacer-xs);

      @media (--laptop) {
        margin-bottom: 0;
      }
    }

    &--secondary {
      button {
        padding: var(--v-spacer-3xs) var(--h-spacer-xxs);
        color: var(--white);

        .header--detached &,
        .header--slided-in & {
          color: var(--gray-400);
        }
      }

      button + button {
        margin-left: var(--h-spacer-xs);

        @media (--laptop) {
          margin-left: var(--h-spacer);
        }
      }
    }
  }

  &__nav-item {
    color: var(--black);
    /* padding: 1.8rem; */

    text-transform: uppercase;

    @media (--laptop) {
      /* padding: 0 1.8rem; */
    }

    &:hover,
    &:focus {
      color: var(--black);
    }
  }

  &__title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    color: currentColor;

    text-decoration: none;
    text-transform: uppercase;

    position: relative;

    width: 35rem;
    height: 4rem;

    @media (--laptop) {
      width: 50rem;
      height: 4rem;
    }

    span {
      display: none;
    }

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;

      path {
        transition: stroke 0.6s cubic-bezier(0.23, 1, 0.32, 1);

        .header--detached & {
          stroke: var(--gray-400);
        }
      }
    }
  }
}
