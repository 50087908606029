.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--loader-z-index);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  user-select: none;
  opacity: 1;

  .loader-panel {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--1989-blue-100);
    color: var(--black);
    z-index: 0;
    transition: transform var(--transition-duration--slow)
      var(--transition-ease--in-out-quint);
    transform-origin: center top;
    transform: scaleY(1);
  }

  &::after {
    background-color: var(--black);
    z-index: 1;
    transition: transform var(--transition-duration--more-slow)
      var(--transition-ease--in-out-quint);
  }

  &--hidden {
    .loader-panel {
      transform: scaleY(0);
      transition-duration: var(--transition-duration--more-slow);
    }
  }

  .admin-bar & {
    @media (--wp-desktop) {
      top: 3.2rem;
    }
  }

  &__title {
    font-size: 4rem;
    line-height: 4rem;
    font-weight: 600;
    color: var(--white);

    @media (--laptop) {
      font-size: 8rem;
      line-height: 8rem;
    }
  }

  &__logo,
  &__title {
    z-index: 2;

    &__content {
      height: 2.4rem;
      width: 17rem;
      /* background-image: url(../../assets/images/logo--white.svg); */
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;

      @media (--laptop) {
        height: 5.3rem;
        width: var(--columns-4);
        /* background-image: url(../../assets/images/logo--white.svg); */
      }
    }
  }

  &__logo {
    width: 90vw;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__bottom {
    position: absolute;
    bottom: var(--margin);
    right: var(--margin);
    z-index: 2;

    @media (--laptop) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  &__text {
    @media (--laptop) {
      width: calc(4 * var(--full-column));
      flex-shrink: 0;
      text-align: right;
    }
  }
}
