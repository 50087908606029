.tooltip {
  visibility: hidden;
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  padding: var(--v-spacer-3xs) var(--h-spacer-xs);
  white-space: nowrap;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  z-index: 1;

  &--complete {
    &:before {
      content: "Complete";
    }

    .row[data-completed="true"] & {
      &:before {
        content: "Restore";
      }
    }
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}
