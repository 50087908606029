.etb-table {
  position: relative;
  margin-top: var(--v-spacer-2xl);
  padding-top: var(--v-spacer-2xl);
  z-index: 1;

  .etb-section-title {
    margin-bottom: var(--v-spacer-l);

    @media (--laptop) {
      margin-bottom: var(--v-spacer-3xl);
    }
  }

  .table-actions {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: var(--v-spacer-s);

    @media (--laptop) {
      flex-direction: row;
    }
  }

  .sorter {
    display: flex;
    align-items: center;
    z-index: 3;

    span {
      margin-right: var(--h-spacer-xs);
    }

    .dropdown {
      color: var(--gray-400);

      &:not(:last-of-type) {
        margin-right: var(--h-spacer-xs);
      }
    }

    .dropdown-container {
      border: 1px solid var(--gray-300);
      width: unset;
      justify-content: flex-start;
      border-radius: 0;
    }
  }

  .completed-checkbox {
    margin-bottom: var(--v-spacer-s);

    @media (--laptop) {
      margin-bottom: 0;
    }
  }

  .rows-container {
    position: relative;
    z-index: 2;
  }

  .row {
    position: relative;
    background-color: var(--white);
    margin: var(--v-spacer-xs) 0;

    &--remove-disabled {
      .row__inner-content {
        /* remove one column */
        @media (--laptop) {
          grid-template-columns: 1fr auto auto auto auto;
        }
      }

      /* remove last column from the flow to avoid breaking the grid */
      .column:last-of-type {
        position: absolute;
      }

      .archive-button {
        right: var(--h-spacer-xxs);

        @media (--laptop) {
          right: unset;
        }
      }
    }

    &[data-completed="true"] {
      opacity: 0.4;

      .archive-button {
        background-image: url("../../assets/icons/undo.svg");
      }
    }

    @media (--laptop) {
      margin: var(--v-spacer-xxs) 0;
      border-radius: 0;
    }

    &__inner-content {
      width: 100%;
      padding: var(--v-spacer) var(--h-spacer-s) var(--v-spacer-3xs);

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--grid-gap);

      @media (--laptop) {
        gap: var(--grid-gap-l);
        padding: var(--v-spacer-xs) var(--v-spacer-xs) var(--v-spacer-xs);
        grid-template-columns: 1fr auto auto auto auto auto;
      }
    }

    label {
      font-family: var(--font-heading);
      text-transform: uppercase;
      color: var(--gray-300);
      letter-spacing: 0.05rem;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:last-of-type {
      @media (--laptop) {
        margin-left: -2rem;
      }
    }

    &:nth-of-type(3),
    &:nth-of-type(4) {
      label {
        margin-bottom: var(--v-spacer-xs);

        @media (--laptop) {
          margin-bottom: 0;
        }
      }
    }
  }

  .album {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: var(--v-spacer-xxs);
    }
  }

  .phrase,
  .beads-style {
    grid-column: 1 / span 2;

    @media (--laptop) {
      grid-column: unset;
    }
  }

  .phrase-input,
  .beads-style-input {
    width: 100%;
    padding: var(--v-spacer-xs) 0 var(--v-spacer-xxs);
    border: none;
    border-bottom: 1px solid var(--black-alpha);
    background-color: transparent;
    border-radius: 0; /* needed for iOS */

    @media (--laptop) {
      padding: var(--v-spacer-3xs) 0;
    }
  }

  .quantity {
    width: 8rem;
    margin-left: auto;

    button {
      padding: var(--v-spacer-xs) var(--h-spacer-xs);
    }
  }

  .remove-row-button {
    right: var(--h-spacer-xxs);
    background-image: url("../../assets/icons/delete.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.2s ease-in-out;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .archive-button {
    right: var(--h-spacer-l);
    background-image: url("../../assets/icons/complete.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

    @media (--laptop) {
      width: 1.8rem;
      height: 1.8rem;
      height: 100%;
      padding: 0;
      border-radius: 0;
      background-color: transparent;
    }
  }

  .remove-row-button,
  .archive-button {
    position: absolute;
    top: var(--v-spacer-xs);
    padding: var(--v-spacer-xs) var(--h-spacer-s);
    width: 1.8rem;
    height: 1.8rem;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    @media (--laptop) {
      position: relative;
      top: 50%;
      right: unset;
      transform: translateY(-50%);
    }

    @media (hover: hover) {
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .add-row-button {
    position: relative;
    z-index: 1;
    width: 100%;
    background-color: var(--white);
    border: none;
    padding: 0;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.5s ease-in-out;

    &.flex {
      display: flex;
    }

    @media (--laptop) {
      border-radius: 0;
    }

    @media (hover: hover) {
      &:hover {
        opacity: 1;
      }
    }

    &:before {
      content: "";
      background-image: url("../../assets/icons/add.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .table-total-bracelets {
    margin-top: var(--v-spacer-xs);
  }
}
