.etb-scroll-to-top {
  position: fixed;
  bottom: var(--v-spacer-s);
  right: var(--h-spacer-s);
  width: 4rem;
  height: 4rem;
  z-index: var(--scroll-to-top-z-index);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: var(--v-spacer-xxs) var(--h-spacer-xxs);
  box-shadow: var(--box-shadow);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1),
    visibility 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);

  @media (--laptop) {
    bottom: var(--v-spacer);
    right: var(--h-spacer);
    width: 3rem;
    height: 3rem;
  }

  &.show {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }

  .backup-modal-open & {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  button {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/icons/up.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}
