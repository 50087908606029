.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.5;
  }
}

.checkmark {
  margin-right: var(--h-spacer-xxs);
  height: 2rem;
  width: 2rem;
  background-color: var(--white-alpha);
  border: 1px solid var(--gray-400);
  position: relative;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3rem;
  height: 3rem;
  background-image: url("../../assets/icons/close.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
