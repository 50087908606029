.counter {
  .counter-container {
    display: flex;
    align-items: center;
    transform: translateX(-15%);

    input {
      width: 2rem;
      text-align: center;
      transform: translateX(0.2rem);

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .increase-button,
    .decrease-button {
      content: "";
      display: flex;
      width: 1rem;
      height: 1rem;
      margin-left: var(--h-spacer-xxs);
      background-image: url("../../assets/icons/chevron.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      &:disabled {
        opacity: 0.3;
      }
    }

    .decrease-button {
      transform: rotate(90deg);
    }

    .increase-button {
      transform: rotate(-90deg);
    }
  }
}
