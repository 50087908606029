.animated-text {
  position: relative;
  white-space: nowrap;
  display: block;

  &--z {
    perspective: 200px;
  }

  &__wrapper {
    overflow: hidden;
    display: block;

    .animated-text--unwrapped & {
      overflow: visible;
      perspective: 600px;
    }

    .animated-text--z & {
      will-change: transform;
      transition: var(--transition-duration--slow)
        var(--transition-ease--out-quint);
      -webkit-transition: var(--transition-duration--slow)
        var(--transition-ease--out-quint);
      transition-property: transform;
      -webkit-transition-property: transform;
      transform-style: preserve-3d;
      transform: translate3d(0, 0, 16px);
    }

    .animated-text--in-view.animated-text--z & {
      transform: translate3d(0, 0, 0);
    }
  }

  &__row {
    display: block;
    transform: translate3d(0, 110%, 0);
    will-change: transform;
    transition: var(--transition-duration--slow)
      var(--transition-ease--out-quint);
    -webkit-transition: var(--transition-duration--slow)
      var(--transition-ease--out-quint);
    transition-property: transform;
    -webkit-transition-property: transform;

    .animated-text--unwrapped & {
      transform-style: preserve-3d;
      transform-origin: center top;
      transform: translate3d(0, 100%, 0) rotateX(-80deg);
      opacity: 0;
      will-change: transform, opacity;
      transition: opacity var(--transition-duration--normal)
          var(--transition-ease--out-quint),
        transform var(--transition-duration--normal)
          var(--transition-ease--out-quint),
        visibility var(--transition-duration--normal) step-end;

      .cursor & {
        visibility: hidden;
      }
    }

    .animated-text--in-view & {
      body:not(.enter):not(.exit) & {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    .animated-text--unwrapped.animated-text--in-view & {
      body:not(.enter):not(.exit) & {
        transition: opacity var(--transition-duration--normal)
            var(--transition-ease--out-quint),
          transform var(--transition-duration--normal)
            var(--transition-ease--out-quint),
          visibility var(--transition-duration--normal) step-start;
        transform: translate3d(0, 0, 0) rotateX(0deg);
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.animated-text--in-view.animated-text--out-top .animated-text__row {
  body:not(.enter):not(.exit) & {
    transform: translate3d(0, -110%, 0);
  }
}
