.etb-section-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--v-spacer-l);

  .section-title-content {
    width: 100%;
    text-align: center;

    @media (--laptop) {
      width: 100%;
    }

    .section-title-title {
      margin-bottom: var(--v-spacer-xs);
    }

    .section-title-intro {
      p + p {
        margin-top: var(--v-spacer-xxs);
      }
    }
  }
}
