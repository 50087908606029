:root {
  --font-heading: "Poppins", serif;
  --font-body: "Montserrat", sans-serif;

  --header-z-index: 700;
  --loader-z-index: 803;
  --cookies-banner-z-index: 802;
  --scroll-to-top-z-index: 801;
  --backup-modal-z-index: 800;
  --backup-buttons-z-index: 800;
  --columns-z-index: 999;

  /* SPACES */

  --h-spacer: 2.8rem;
  --h-spacer-3xs: 0.2rem;
  --h-spacer-xxs: 0.5rem;
  --h-spacer-xs: 1rem;
  --h-spacer-s: calc(var(--h-spacer) / 2);
  --h-spacer-l: calc(var(--h-spacer) * 1.5);
  --h-spacer-xl: calc(var(--h-spacer) * 2);
  --h-spacer-2xl: calc(var(--h-spacer) * 2.5);
  --h-spacer-3xl: calc(var(--h-spacer) * 3);

  --v-spacer: 3rem;
  --v-spacer-3xs: 0.2rem;
  --v-spacer-xxs: 0.5rem;
  --v-spacer-xs: 1rem;
  --v-spacer-s: calc(var(--v-spacer) / 2);
  --v-spacer-l: calc(var(--v-spacer) * 1.5);
  --v-spacer-xl: calc(var(--v-spacer) * 2);
  --v-spacer-2xl: calc(var(--v-spacer) * 2.5);
  --v-spacer-3xl: calc(var(--v-spacer) * 3);

  /* RADIUSES */

  --radius-s: 0.5rem;
  --radius: 2rem;

  /* GRID */
  --grid-gap-xs: var(--h-spacer-xs);
  --grid-gap-s: var(--h-spacer-s);
  --grid-gap: var(--h-spacer);
  --grid-gap-l: var(--h-spacer-l);

  /* FILTERS */

  --hover-link: 0.7;
  --opacity: 0.5;
  --disabled-opacity: 0.2;
  --blur: 1rem;

  --polaroid-border: 0.6rem;

  --box-shadow: 0px 1px 8px var(--black-alpha);
}
