.etb-results {
  width: 100%;
  position: relative;
  border-top: 1px solid var(--black-alpha);
  margin-top: var(--v-spacer-l);
  padding-top: var(--v-spacer-l);

  @media (--laptop) {
    margin-top: var(--v-spacer-3xl);
    padding-top: var(--v-spacer-3xl);
  }

  .results-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: var(--v-spacer-s);
    background-color: var(--white-alpha);
    /* padding: var(--v-spacer-xs) var(--h-spacer-xs); */

    &:last-of-type {
      margin-top: var(--v-spacer-xl);
    }

    @media (--laptop) {
      margin-bottom: var(--v-spacer);
      /* padding: var(--v-spacer-s) var(--h-spacer-s); */

      &:last-of-type {
        margin-top: var(--v-spacer-xl);
      }
    }
  }

  .beads-style-container {
    width: 100%;
    padding: var(--v-spacer-xs) var(--h-spacer-xs);

    @media (--laptop) {
      padding: var(--v-spacer-3xs) var(--h-spacer-xxs);
    }
  }

  .beads-count-container {
    width: 100%;
    padding: var(--v-spacer-xs) var(--h-spacer-xs);

    @media (--laptop) {
      padding: var(--v-spacer-xs) var(--h-spacer-3xs);
    }
  }

  .beads-style-container {
    border-bottom: 1px solid var(--1989-blue-200);
  }

  .beads-style {
    width: 100%;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .beads-count {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
    gap: var(--grid-gap);

    @media (--laptop) {
      grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
      gap: var(--grid-gap-xs);
    }
  }

  .character {
    display: flex;
    flex-direction: column;
    align-items: center;

    .char {
      margin-bottom: var(--v-spacer-xxs);

      @media (--laptop) {
        margin-bottom: 0;
      }
    }
  }
}
