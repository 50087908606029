.stats {
  margin-top: var(--v-spacer-l);
  padding: var(--v-spacer-l) 0;
  border-top: 1px solid var(--black);

  @media (--laptop) {
    margin-top: var(--v-spacer-3xl);
    padding: var(--v-spacer-3xl) 0;
  }

  .stats-container {
    display: flex;
    flex-wrap: wrap;
    gap: var(--grid-gap);
    padding: var(--v-spacer-xs) var(--h-spacer-xs);
    background-color: var(--white-alpha);

    @media (--laptop) {
      padding: var(--v-spacer-s) var(--h-spacer-s);
    }
  }
}
