.spinner {
  border: 3px solid var(--white-alpha);
  border-top: 3px solid var(--white);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: spin 1s linear infinite;
  display: none;

  &.show {
    display: block;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
