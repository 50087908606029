.dropdown {
  position: relative;
  display: flex;

  .dropdown-container {
    width: 12rem;
    padding: var(--v-spacer-3xs) var(--v-spacer-xxs);
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:after {
      content: "";
      display: flex;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: var(--h-spacer-xxs);
      background-image: url("../../assets/icons/chevron.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transition: transform 0.2s ease-in-out;
    }
  }

  .options-container {
    width: 100%;
    position: absolute;
    top: calc(100% + 0.4rem);
    left: 0;
    right: 0;
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    border-radius: var(--radius-s);
    padding: var(--v-spacer-xxs) var(--h-spacer-xxs);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    z-index: 1;
    display: flex;
    flex-direction: column;

    li {
      padding: var(--v-spacer-xxs) var(--h-spacer-xxs);
      text-transform: uppercase;
      border: none;
      border-radius: 2rem;
      font-weight: 600;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;

      &:not(:last-of-type) {
        margin-bottom: var(--v-spacer-xxs);
      }

      @media (--laptop) {
        padding: var(--v-spacer-3xs) var(--h-spacer-xxs);
      }

      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }

      &.album-no-album {
        opacity: 0.5;
      }

      &[aria-selected="true"] {
        outline: 2px solid var(--1989-blue-200);
      }

      &.removable {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .beads-style-option-label {
          width: 80%;
          overflow: hidden;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .beads-style-remove-option {
          width: 1.2rem;
          height: 1.2rem;
          background-image: url("../../assets/icons/delete.svg");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }

    .beads-style-add {
      display: flex;
      flex-direction: column;
      padding-bottom: var(--v-spacer-xxs);
      margin-bottom: var(--v-spacer-xxs);
      border-bottom: 1px solid var(--gray-100);
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          opacity: 0.5;
        }
      }

      &.no-display {
        display: none;
      }

      .beads-style-add-title {
        font-family: var(--font-heading);
        font-size: 0.7rem;
        text-transform: uppercase;
        color: var(--gray-300);
        letter-spacing: 0.05rem;
      }

      .beads-style-add-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .beads-style-add-label {
        width: 80%;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .beads-style-add-button {
        width: 2rem;
        height: 2rem;
        background-image: url("../../assets/icons/add.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  &.active {
    .dropdown-container {
      &:after {
        transform: rotateX(180deg);
      }
    }

    .options-container {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &:focus-within .options-container {
    display: block;
  }
}
