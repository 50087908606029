.etb-backup-modal {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black-alpha-dark);
    z-index: var(--backup-modal-z-index);
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1),
      visibility 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);

    &.open {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;

      .modal-content {
        transform: translate(-50%, -50%);
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: var(--white);
      padding: var(--v-spacer-l) var(--h-spacer);
      width: 90%;
      max-width: 400rem;
      transform: translate(-50%, 0);
      transition: transform 2.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      text-align: center;

      @media (--laptop) {
        width: 50%;
        padding: var(--v-spacer-l) var(--h-spacer-l) var(--v-spacer);
      }
    }

    .close-modal-button {
      position: absolute;
      top: var(--v-spacer-3xs);
      right: var(--v-spacer-3xs);
      cursor: pointer;
      width: 4rem;
      height: 4rem;
      background-image: url("../../assets/icons/close.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;

      @media (hover: hover) {
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .modal-title {
      margin-bottom: var(--v-spacer-xs);
    }

    .modal-subtitle {
      margin-bottom: var(--v-spacer-xxs);
    }

    .modal-error {
      display: none;
      margin-top: var(--v-spacer-s);
      color: var(--red-red-200);
      align-self: center;

      &.show {
        display: block;
      }
    }

    .modal-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .export-button {
      margin-right: var(--h-spacer);
    }

    .import-button,
    .export-button {
      width: 12rem;
      height: 3rem;
      position: relative;
      background-color: var(--1989-blue-200);
      color: var(--white);
      padding: var(--v-spacer-xxs) var(--h-spacer-xs);
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      margin-top: var(--v-spacer);
      opacity: 1;
      transition: opacity 0.3s ease-in-out;

      @media (hover: hover) {
        &:hover {
          opacity: 0.8;
        }
      }

      &.hide-label {
        span {
          width: 100%;
          display: none;
        }
      }
    }
  }
}
