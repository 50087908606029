* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

html,
body {
  box-sizing: border-box;
}

html {
  font-size: 2.6666666667vw;
}

@media (min-width: 1025px) {
  html {
    font-size: 0.7874015748vw;
  }
}

body {
  width: 100vw;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

ul,
ol {
  margin: 0;
  padding: 0;

  list-style: none;
}

select,
button,
input,
textarea {
  padding: 0;
  border: none;
  outline: none;
  background: none;
}

button {
  color: inherit;
  font-family: inherit;
  font-size: inherit;

  cursor: pointer;
}

figure {
  display: block;
  margin: 0;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

input[type="number"] {
  -moz-appearance: textfield;
}
