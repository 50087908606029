.etb-cookies {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  width: 100%;
  padding: var(--v-spacer) var(--h-spacer-s);
  z-index: var(--cookies-banner-z-index);
  transform: translateY(100%);
  transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

  @media (--laptop) {
    right: var(--polaroid-border);
    width: 35rem;
    padding: var(--v-spacer-s) var(--h-spacer-s);
  }

  &.show {
    transform: translateY(0);
  }

  .banner-content {
    display: flex;
    flex-direction: column;
  }

  .banner-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--v-spacer-s);

    @media (--laptop) {
      margin-bottom: var(--v-spacer-xxs);
    }

    .banner-title {
      font-size: 2.4rem;
      line-height: 2.4rem;
      font-weight: 600;

      @media (--laptop) {
        font-size: 1.8rem;
        line-height: 2rem;
      }
    }
  }

  .banner-text {
    font-size: 1.4rem;
    line-height: 1.5rem;
    margin-bottom: var(--v-spacer-s);

    @media (--laptop) {
      font-size: 1.1rem;
    }
  }

  .banner-close-button {
    width: 4.5rem;
    height: 4.5rem;
    background-image: url("../../assets/icons/close.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

    @media (--laptop) {
      width: 3rem;
      height: 3rem;
    }
  }

  .banner-cta {
    align-self: center;

    button {
      background-color: var(--1989-blue-200);
      color: var(--white);
      padding: var(--v-spacer-xs) var(--h-spacer-xs);
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      line-height: 1.5rem;

      @media (--laptop) {
        padding: var(--v-spacer-xxs) var(--h-spacer-xs);
        font-size: 1.1rem;
      }
    }
  }
}
