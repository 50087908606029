:root {
  --white: #ffffff;
  --black: #000000;

  --white-alpha: rgba(255, 255, 255, 0.6);
  --black-alpha: rgba(0, 0, 0, 0.2);
  --black-alpha-dark: rgba(0, 0, 0, 0.7);

  --1989-blue-100: #b3cfe5;
  --1989-blue-200: #5b99cf;

  --gray-100: #e2e8f0;
  --gray-200: #cbd5e0;
  --gray-300: #718096;
  --gray-400: #4a5568;

  /* ALBUMS */

  --taylor-swift-green-100: #b1d89a;
  --taylor-swift-green-200: #1c8c1c;
  --fearless-gold-100: #ecd285;
  --fearless-gold-200: #b08816;
  --speak-now-purple-100: #c49df9;
  --speak-now-purple-200: #8421d4;
  --red-red-100: #e5a0a0;
  --red-red-200: #c42222;
  --1989-blue-50: #a3d0f4;
  --1989-blue-300: #0083d8;
  --reputation-black-100: #909090;
  --reputation-black-200: #000000;
  --lover-pink-100: #f2c6e7;
  --lover-pink-200: #d174cc;
  --folklore-gray-100: #cccccc;
  --folklore-gray-200: #767676;
  --evermore-brown-100: #bfa795;
  --evermore-brown-200: #865228;
  --midnights-blue-100: #9cbcf0;
  --midnights-blue-200: #0b1871;
  --ttpd-sepia-100: #edecec;
  --ttpd-sepia-200: #938974;
}
