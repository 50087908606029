footer {
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: var(--v-spacer) var(--h-spacer);
  box-sizing: border-box;
  color: var(--gray-400);
  background-color: var(--white);

  @media (--laptop) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    padding: var(--v-spacer-s) var(--h-spacer);
  }

  h1 {
    font-size: 2.3rem;
    line-height: 2rem;
    font-weight: 600;
    margin-bottom: var(--h-spacer-s);

    @media (--laptop) {
      font-size: 2rem;
      line-height: 2.8rem;
      margin-bottom: 0;
    }

    display: none;
  }

  .footer-logo {
    width: 30rem;

    @media (--laptop) {
      width: 35rem;
    }

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;

      path {
        stroke: var(--gray-400);
      }
    }
  }

  h2 {
    margin-bottom: var(--h-spacer-xxs);
  }

  .footer-subtitle {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .footer-credits {
    font-size: 1rem;
    line-height: 1.4rem;

    a {
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      text-decoration: underline;
    }
  }

  .footer-year {
    margin-top: var(--v-spacer-s);

    @media (--laptop) {
      margin-top: 0;
    }
  }
}
