:root {
  --transparent: rgba(0, 0, 0, 0);
  --page-background: var(--1989-blue-100-gradient),
    var(--1989-blue-200-gradient2), var(--1989-blue-100-gradient3),
    var(--1989-blue-200-gradient2), var(--1989-blue-100-gradient2),
    var(--1989-blue-200-gradient), var(--1989-blue-200-gradient3),
    var(--1989-blue-200-gradient);

  --1989-blue-200-gradient: linear-gradient(
    to right,
    var(--1989-blue-200) 0%,
    var(--transparent) 100%
  );
  --1989-blue-200-gradient2: radial-gradient(
    circle at bottom right,
    var(--1989-blue-200),
    var(--transparent) 50%
  );
  --1989-blue-100-gradient: radial-gradient(
    circle at bottom left,
    var(--1989-blue-100) 0%,
    var(--transparent) 70%
  );
  --1989-blue-100-gradient2: radial-gradient(
    circle at 60% center,
    var(--1989-blue-100) 0%,
    var(--transparent) 55%
  );
  --1989-blue-100-gradient3: radial-gradient(
    circle at 100% center,
    var(--1989-blue-100) 0%,
    var(--transparent) 30%
  );
  --1989-blue-200-gradient: radial-gradient(
    circle at top right,
    var(--1989-blue-200),
    var(--transparent) 100%
  );
  --1989-blue-200-gradient2: radial-gradient(
    circle at center left,
    var(--1989-blue-200),
    var(--transparent) 25%
  );
  --1989-blue-200-gradient3: radial-gradient(
    circle at 60% bottom,
    var(--1989-blue-200),
    var(--transparent) 75%
  );
  --background-texture: url("https://img.nickpic.host/56Mk0X.png");
}

main {
  background: var(--page-background);
  background-attachment: fixed;
}
