.x {
  opacity: 0;
  transition: opacity 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;

  &--slide-up {
    transition-property: opacity, transform;
    transform: translateY(5rem);
  }

  &--in-view {
    opacity: 1;
    transform: translateY(0);
  }
}
