* {
  font-family: var(--font-body);
}

body {
  background-color: var(--white);
  font-family: var(--font-body);
  color: var(--gray-400);
}

main {
  min-height: calc(var(--vh, 1vh) * 100);
  margin: var(--polaroid-border);
}

/* remove outline for non-keyboard :focus */
*:focus:not(:focus-visible) {
  outline: none;
}

/* customize .focus-visible */
*:focus-visible {
  outline: 2px solid var(--1989-blue-200);
}

button {
  transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
}

input {
  font-family: var(--font-body);
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);
}

.font-heading {
  font-family: var(--font-heading);
}

.font-body {
  font-family: var(--font-body);
}

.no-display {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

.container {
  max-width: 100rem;
  margin: 0 auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;

  /* this container is on top of the `polaroid` border of the site */
  /* so we need to account for that to match the padding of the page */
  &--polaroid {
    padding: 1.5rem calc(1.5rem + var(--polaroid-border));
  }

  @media (--laptop) {
    padding: 1rem;
  }
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.text-heading {
  font-size: 6rem;
  line-height: 6rem;
  font-weight: 600;

  @media (--laptop) {
    font-size: 10rem;
    line-height: 10rem;
  }
}

.text-title {
  font-size: 3.8rem;
  line-height: 3.8rem;
  font-weight: 600;
  color: var(--gray-400);

  @media (--laptop) {
    font-size: 5rem;
    line-height: 4.8rem;
  }
}

.text-title-2 {
  font-size: 2.8rem;
  line-height: 2.8rem;
  font-weight: 600;
  color: var(--gray-400);

  @media (--laptop) {
    font-size: 4rem;
    line-height: 3.8rem;
  }
}

.text-subtitle {
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--gray-400);
}

.text-subtitle-2 {
  font-size: 2.6rem;
  line-height: 2.6rem;
  font-weight: 600;
  color: var(--gray-400);

  @media (--laptop) {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

.text-subtitle-3 {
  font-size: 2.6rem;
  line-height: 2.6rem;
  font-weight: 600;
  color: var(--gray-400);

  @media (--laptop) {
    font-size: 3rem;
    line-height: 3.8rem;
  }
}

.text-body {
  font-size: 1rem;
  line-height: 1.2rem;
  color: var(--gray-400);
}

.extra-light {
  font-weight: 200;
}

.italic {
  font-style: italic;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: normal;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.black {
  color: var(--black);
}

.white {
  color: var(--white);
}

.gray-100 {
  color: var(--gray-100);
}

.gray-200 {
  color: var(--gray-200);
}

.gray-300 {
  color: var(--gray-300);
}

.gray-400 {
  color: var(--gray-400);
}

.blue-100 {
  color: var(--1989-blue-100);
}

.blue-200 {
  color: var(--1989-blue-200);
}

.album-taylor-swift {
  color: var(--taylor-swift-green-200);
  background-color: var(--taylor-swift-green-100);
}

.album-taylor-swift-secondary {
  color: var(--taylor-swift-green-200);
}

.album-fearless {
  color: var(--fearless-gold-200);
  background-color: var(--fearless-gold-100);
}

.album-fearless-secondary {
  color: var(--fearless-gold-200);
}

.album-speak-now {
  color: var(--speak-now-purple-200);
  background-color: var(--speak-now-purple-100);
}

.album-speak-now-secondary {
  color: var(--speak-now-purple-200);
}

.album-red {
  color: var(--red-red-200);
  background-color: var(--red-red-100);
}

.album-red-secondary {
  color: var(--red-red-200);
}

.album-1989 {
  color: var(--1989-blue-300);
  background-color: var(--1989-blue-50);
}

.album-1989-secondary {
  color: var(--1989-blue-300);
}

.album-reputation {
  color: var(--reputation-black-200);
  background-color: var(--reputation-black-100);
}

.album-reputation-secondary {
  color: var(--reputation-black-200);
}

.album-lover {
  color: var(--lover-pink-200);
  background-color: var(--lover-pink-100);
}

.album-lover-secondary {
  color: var(--lover-pink-200);
}

.album-folklore {
  color: var(--folklore-gray-200);
  background-color: var(--folklore-gray-100);
}

.album-folklore-secondary {
  color: var(--folklore-gray-200);
}

.album-evermore {
  color: var(--evermore-brown-200);
  background-color: var(--evermore-brown-100);
}

.album-evermore-secondary {
  color: var(--evermore-brown-200);
}

.album-midnights {
  color: var(--midnights-blue-200);
  background-color: var(--midnights-blue-100);
}

.album-midnights-secondary {
  color: var(--midnights-blue-200);
}

.album-ttpd {
  color: var(--ttpd-sepia-200);
  background-color: var(--ttpd-sepia-100);
}

.album-ttpd-secondary {
  color: var(--ttpd-sepia-200);
}

.album-no-album {
  color: var(--gray-300);
  background-color: var(--gray-100);
}

.album-no-album-secondary {
  color: var(--gray-300);
}

.underlineable {
  position: relative;
  padding-bottom: 0.4rem;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10%;
    width: 80%;
    height: 1px;
    background-color: currentColor;
    opacity: 0.4;
    transform: scaleX(1);
    transform-origin: bottom right;
    transition: transform 0.6s var(--transition-ease--out-quint);

    @media (--laptop) {
      width: 100%;
      left: 0;
      opacity: 1;
      transform: scaleX(0);
    }
  }

  &:hover::after,
  &--active::after {
    transform-origin: bottom left;
    transform: scaleX(1);
  }
}

.etb {
  padding-bottom: var(--v-spacer-3xl);

  @media (--laptop) {
    padding-bottom: var(--v-spacer);
  }
}
